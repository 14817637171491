import React, { Component } from 'react';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenu />
                <div id='page-wrapper'>
                    <main>
                        {this.props.children}
                    </main>
                </div>
            </div>
        );
    }
}
