import React, { Component } from 'react';

export class WishListDeleteButton extends Component {
    static displayName = WishListDeleteButton.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.buttonFunction = this.buttonFunction.bind(this);
    }

    async componentDidMount() {
        this._isMounted = true;
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    buttonFunction = () => {
        if (this._isMounted && navigator.onLine) { // werkt nog niet.
            this.props.buttonFunction({ itemId: this.props.itemId });
        }
    }

    render() {
        var classNames = this.props.className !== undefined ? this.props.className : "btn-add-to-wishlist";

        return (
            <button className={classNames} onClick={this.buttonFunction}>
                {this.props.children}
            </button>
        )
    }
}