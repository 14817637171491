import React, { Component } from 'react';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { LogoutModal } from './modals/LogoutModal';
import AuthService from './auth/authService';
import './NavMenu.css';


export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <nav id="navbar">
                <ul>
                    <NavItem>
                        <NavLink to="/" exact className="nav-link" activeClassName="active">
                            <div className="logo"></div>
                        </NavLink>
                    </NavItem>

                    {
                        AuthService.isLoggedIn() ?
                            <>
                                <NavItem className="login-logout-navitem">
                                    <LogoutModal />
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/wishlist" className="nav-link" activeClassName="active">
                                        <span className="nav-icon"><i className="fas fa-list-ul"></i></span>
                                    </NavLink>
                                </NavItem>
                            </>
                            :
                            <NavItem className="login-logout-navitem">
                                <a className="nav-link" href="/Identity/Account/Login">
                                    <span className="nav-icon"><i className="fas fa-sign-in-alt"></i></span>
                                </a>
                            </NavItem>
                    }

                    <NavItem>
                        <NavLink to="/companies" className="nav-link" activeClassName="active">
                            <span className="nav-icon"><i className="fas fa-users"></i></span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/search" className="nav-link" activeClassName="active">
                            <span className="nav-icon"><i className="fas fa-search"></i></span>
                        </NavLink>
                    </NavItem>
                </ul>
            </nav>
        );
    }
}
