import React, { Component } from 'react';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import ReadMoreAndLess from 'react-read-more-less';
import { WishListDeleteButton } from './WishListDeleteButton';
import HttpClient, { Methods } from '../HttpClient';
import AuthService from './auth/authService';

export class WishList extends Component {
    static displayName = WishList.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            wishListOffers: [],
            wishListRequests: [],
            loading: true
        };

        this.init = this.init.bind(this);
        this.renderWishList = this.renderWishList.bind(this);
        this.deleteFromWishList = this.deleteFromWishList.bind(this);
    }

    async componentDidMount() {
        this._isMounted = true;
        this.init();
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    async setSuccesfulToast(message) {
        ToastsStore.success(message);
    }

    async fetchWishList() {
        var data = null;

        await HttpClient.doRequest(Methods.GET, 'api/WishList').then(async function (result) {
            if (result.redirected) {
                AuthService.accessDenied();
            } else {
                data = await result.json();
                window.localStorage.setItem('wishlist', JSON.stringify(data));
            }
        }).catch((e) => {
        });

        return data;
    }

    async init() {
        let data = [];

        if (navigator.onLine) {
            data = await this.fetchWishList();
            if (data == 'relogged') {
                data = await this.fetchWishList();
            }
        } else {
            var parsed = JSON.parse(window.localStorage.getItem('wishlist'));
            data = parsed != "" && parsed != null ? parsed : [];
        }

        if (this._isMounted) {
            if (data != null) {
                this.setState((prevState) => ({
                    prevState,
                    wishListOffers: data.wishListOffers != null ? data.wishListOffers : [],
                    wishListRequests: data.wishListRequests != null ? data.wishListRequests : [],
                    loading: false
                }));
            } else {
                this.setState((prevState) => ({
                    prevState,
                    loading: false
                }));
            }
        }
    }

    async deleteFromWishList(itemInfo) {
        if (!this.state.deleting) {
            this.setState((prevState) => ({
                prevState,
                deleting: true
            }));

            var _response = await HttpClient.doRequest(Methods.DELETE, 'api/WishList', itemInfo.itemId);
            if (_response.status === 200 && this._isMounted) {
                this.state.wishListOffers.map((v, i) => {
                    if (itemInfo.itemId == v.offerId) {
                        var _wishListOffers = this.state.wishListOffers;
                        _wishListOffers.splice(i, 1);
                        this.setSuccesfulToast("Verwijderd!");

                        this.setState((prevState) => ({
                            prevState,
                            wishListOffers: _wishListOffers,
                            deleting: false
                        }));
                    }
                });

                this.state.wishListRequests.map((v, i) => {
                    if (itemInfo.itemId == v.requestId) {
                        var _wishListRequests = this.state.wishListRequests;
                        _wishListRequests.splice(i, 1);
                        this.setSuccesfulToast("Verwijderd!");

                        this.setState((prevState) => ({
                            prevState,
                            wishListRequests: _wishListRequests,
                            deleting: false
                        }));
                    }
                });
            }
        }
    }

    updateList() {
        this.setState((prevState) => ({
            prevState,
            loading: true
        }));

        this.init();
    }

    renderWishList(wishListOffers, wishListRequests) {
        return (wishListOffers.length === 0 && wishListRequests.length === 0)
            ? (
                <div>
                    <h3>Er staat nog niets in uw verlanglijst.</h3>
                </div>
            ) : (
                <div>
                    {wishListOffers.map((item, index) => {
                        return (
                            <div key={index} className="row">
                                <div className="col-2">
                                    <WishListDeleteButton disableOnClick={false} wishlistbutton="false" className="btn" wishListOffers={[]} wishListRequests={[]} itemId={item.offerId} buttonFunction={this.deleteFromWishList} >
                                        <img src="/media/images/delete-icon.png" />
                                    </WishListDeleteButton>
                                </div>
                                <div className="col-10">
                                    <ReadMoreAndLess charLimit={150} readMoreText="Lees meer" readLessText="Lees minder">
                                        {item.offerDescription}
                                    </ReadMoreAndLess>
                                    <h3>Categorie:</h3>
                                    <h4>{item.category}</h4>

                                    <h3>Organisatie:</h3>
                                    <h4>{item.companyName}</h4>
                                </div>
                                <hr />
                            </div>
                        )
                    })
                    }

                    {wishListRequests.map((item, index) => {
                        return (
                            <div key={index} className="row">
                                <div className="col-2">
                                    <WishListDeleteButton className="btn" itemId={item.requestId} buttonFunction={this.deleteFromWishList} >
                                        <img src="/media/images/delete-icon.png" />
                                    </WishListDeleteButton>
                                </div>
                                <div className="col-10">
                                    <ReadMoreAndLess charLimit={150} readMoreText="Lees meer" readLessText="Lees minder">
                                        {item.requestDescription}
                                    </ReadMoreAndLess>
                                    <h3>Catgorie:</h3>
                                    <h4>{item.category}</h4>

                                    <h3>Bedrijf:</h3>
                                    <h4>{item.companyName}</h4>
                                </div>
                                <hr />
                            </div>
                        )
                    })
                    }
                </div>
            );
    }

    render() {
        let wishList = this.state.loading ?
            <div className="text-center">
                <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            : this.renderWishList(this.state.wishListOffers, this.state.wishListRequests);
        return (
            <div id='page-wishlist'>
                <h1>Mijn verlanglijst</h1>
                {wishList}
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} lightBackground />
            </div>
        )
    }
}