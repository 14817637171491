import React, { Component } from 'react';
import { NavOfferRequest } from './NavOfferRequest';
import { OfferOverview } from './OfferOverview';
import { RequestOverview } from './RequestOverview';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

export class Search extends Component {
    static displayName = Search.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            wishListItems: [],
            searchQuery: ""
        }
    }

    async componentDidMount() {
        this._isMounted = true;
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    tabChange = (index) => {
        if (this._isMounted && index !== this.state.activeTab) {
            this.setState((prevState) => ({
                prevState,
                activeTab: index,
                searchQuery: ""
            }));
        }
    }

    updateSearchQuery = (q) => {
        if (this._isMounted) {
            this.setState(prevState => ({
                prevState,
                searchQuery: q
            }));
        }
    }

    setSuccesfulToast = (message) => {
        ToastsStore.success(message)
    }

    render() {
        const search = true;
        let tab = null;

        if (this.state.activeTab === 0) {
            tab = <OfferOverview search={search} wishListOffers={this.state.wishListOffers} wishListRequests={this.state.wishListRequests} searchQuery={this.state.searchQuery} updateSearchQuery={this.updateSearchQuery} setSuccesfulToast={this.setSuccesfulToast} selectedCategories={[]} />;
        } else if (this.state.activeTab === 1) {
            tab = <RequestOverview search={search} wishListOffers={this.state.wishListOffers} wishListRequests={this.state.wishListRequests} searchQuery={this.state.searchQuery} updateSearchQuery={this.updateSearchQuery} setSuccesfulToast={this.setSuccesfulToast} selectedCategories={[]} />;
        }

        return (
            <div id='page-home'>
                <NavOfferRequest tabChange={this.tabChange} />
                {tab}
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} lightBackground />
            </div>
        );
    }
}
