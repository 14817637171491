import React, { Component } from 'react';

export class CategoryCheckBox extends Component {
    static displayName = CategoryCheckBox.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            label: this.props.children,
            checked: false
        };
    }

    async componentDidMount() {
        this._isMounted = true;
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    update = () => {
        if (this._isMounted) {
            this.props.update({ label: this.state.label, checked: !this.state.checked });
            this.setState((prevState) => ({
                prevState,
                checked: !prevState.checked
            }));
        }
    }

    render() {
        return (
            <div className="checkbox">
                <input type="checkbox" id={this.state.label?.replace(/\s/g, "")} onChange={this.update} checked={this.state.checked} />
                <label className="checkmark" htmlFor={this.state.label?.replace(/\s/g, "")}></label>
            </div>
        )
    }
}