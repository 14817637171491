import React, { Component } from 'react';
import { CompaniesOverview } from './CompaniesOverview';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

export class Companies extends Component {
    static displayName = Companies.name;

    constructor(props) {
        super(props);
        this.state = {}
    }

    setSuccesfulToast = (message) => {
        ToastsStore.success(message)
    }

    render() {
        return (
            <div id='page-companies'>
                <CompaniesOverview setSuccesfulToast={this.setSuccesfulToast} />
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} lightBackground />
            </div>
        );
    }
}
