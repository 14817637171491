export default class ProfileService {
  static _userStorageKey = "user";

  static getUserDetails = () => {
    return JSON.parse(
      localStorage.getItem(this._userStorageKey)
    );
  }

  static isInRole = (role) => {
    var userDetails = this.getUserDetails();

    return userDetails !== null
      ? userDetails.profile.roleNames.includes(role) ?? false
      : false;
  };

  static getLocale = () => {
    var userDetails = this.getUserDetails();

    return userDetails !== null
      ? userDetails.profile.details.locale
      : "nl_NL";
  };

  static setLocale = (locale) => {
    var userDetails = this.getUserDetails();

    if (userDetails !== null) {
      userDetails.profile.details.locale = locale;
      localStorage.setItem(this._userStorageKey, JSON.stringify(userDetails));
    }
  };

  static updateTokenInfo = (tokenInfo) => {
    var userDetails = this.getUserDetails();

    if (userDetails !== null) {
      userDetails.token = tokenInfo.token;
      userDetails.refreshToken = tokenInfo.refreshToken;
      userDetails.expiryDate = tokenInfo.expiryDate;
      localStorage.setItem(this._userStorageKey, JSON.stringify(userDetails));

      return true;
    } else {
      return false;
    }
  };
}
