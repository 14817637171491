import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { WishList } from './components/WishList';
import { Search } from './components/Search';
import { Companies } from './components/Companies';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/search' component={Search} />
                    <Route path='/wishlist' component={WishList} />
                    <Route path='/companies' component={Companies} />
                    <Route path='/login' render={() => { window.location.replace("/Identity/Account/Login"); return null }} />
                </Switch>
            </Layout>
        );
    }
}
