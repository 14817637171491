class AuthService {
  _isLoggedIn = null;

  isLoggedIn = () => {
    if (this._isLoggedIn === null) {
      this._isLoggedIn = window.localStorage.getItem("isLoggedIn") ?? false;
    }

    return this._isLoggedIn;
  };

  clearLogin = () => {
    this._isLoggedIn = null;
    window.localStorage.removeItem("isLoggedIn");
  };

  accessDenied = () => {
    this.clearLogin();
    window.location.href = "/Identity/Account/Login";
  };

  logOut = () => {
    this.clearLogin();
    window.location.href = "/Identity/Account/Logout";
  };
}

const instance = new AuthService();

export default instance;
