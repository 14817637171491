import React, { Component } from 'react';
import HttpClient, { Methods } from '../HttpClient';

export class CompaniesOverview extends Component {
    static displayName = CompaniesOverview.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            companies: [],
            loading: true
        };

        this.renderCompaniesTable = this.renderCompaniesTable.bind(this);
        this.triggerSearchUpdate = this.triggerSearchUpdate.bind(this);
    }

    async componentDidMount() {
        this._isMounted = true;

        let data = null;

        if (navigator.onLine) {
            await HttpClient.doRequest(Methods.GET, 'api/Companies').then(async function (result) {
                data = await result.json();
                window.localStorage.setItem('companies', JSON.stringify(data));
            });
        } else {
            var parsed = JSON.parse(window.localStorage.getItem('companies'));
            data = parsed != null ? parsed : [];
        }

        if (this._isMounted) {
            // Set companies
            this.setState((prevState) => ({
                prevState,
                companies: data,
                loading: false
            }));
        }
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    renderCompaniesTable() {
        var companies = new Map();

        this.state.companies.map((company) => {
            var char = company.name.substring(0, 1);

            if (companies.get(char) == undefined) {
                companies.set(char, []);
            }

            companies.get(char)[companies.get(char).length] = company;
        });

        var sortedCompanies = new Map([...companies.entries()].sort());
        var companyContents = [];
        var characters = [];

        const parser = new DOMParser();

        sortedCompanies.forEach((companyList, key) => {
            characters.push(key);
            companyContents.push(
                <div key={key}>
                    <h2 className="company-letter" id={key}>{key}</h2>
                    {
                        companyList.map((company) => {
                            return (
                                <div key={company.name} className="company-row">
                                    <header>
                                        <h3>{parser.parseFromString(`<!doctype html><body>${company.name}`, 'text/html').body.textContent}</h3>
                                    </header>
                                    <div>
                                        {parser.parseFromString(`<!doctype html><body>${company.pitch}`, 'text/html').body.textContent}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        });

        var search = (
            <div className="search-characters">
                <div>Snel naar:</div>
                {characters.map((char) =>
                    <a key={char} href={'/companies#' + char}>{char}</a>
                )}
            </div>
        );

        return (
            <div>
                {search}
                {companyContents}
            </div>
        );
    }

    triggerSearchUpdate(input) {
        this.searchUpdate(input.target.value);
    }

    searchUpdate(value) {
        this.props.updateSearchQuery(value);
    }

    render() {
        let contents = this.state.loading ?
            <div className="text-center">
                <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            : this.renderCompaniesTable();


        return (
            <div id="CompaniesOverview">
                <h1>Deelnemers Beursvloer Tilburg {new Date().getFullYear()}</h1>
                {contents}
            </div>
        );
    }
}
