import React, { Component } from 'react';

export class WishListAddRemoveButton extends Component {
    static displayName = WishListAddRemoveButton.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {};

        this.buttonFunction = this.buttonFunction.bind(this);
    }

    async componentDidMount() {
        this._isMounted = true;
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    buttonFunction = () => {
        if (navigator.onLine) {
            if (this.props.inWishlist) {
                this.props.deleteFromWishList(this.props.itemId);
            } else {
                this.props.addToWishList(this.props.itemId);
            }
        }
    }

    render() {
        var img = (!this.props.inWishlist
            ? <img src="/media/images/add-icon.png" alt="" />
            : <img src="/media/images/add-complete-icon.png" alt="" />);

        var classNames = this.props.className !== undefined ? this.props.className : "btn-add-to-wishlist";

        return (
            <button className={classNames} onClick={this.buttonFunction}>
                {this.props.inWishlist ? 'Verwijderen uit verlanglijst' : 'Voeg toe aan verlanglijst'}
                {img}
            </button>
        )
    }
}