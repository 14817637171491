import React, { Component } from 'react';

export class NavButtonOfferRequest extends Component {
    static displayName = NavButtonOfferRequest.name;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    clicked = () => {
        this.props.onClick(this.props.index);
    }

    render() {
        return (
            <button disabled={this.props.isLoading} className={this.props.isActive ? "btn-offer-request active" : "btn-offer-request"} onClick={this.clicked}>{this.props.children}</ button>
        )
    }
}