import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AuthService from '../auth/authService';

export class LogoutModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        return (
            <div>
                <Button className="nav-link btn-logout" onClick={this.toggle}><span className="nav-icon"><i className="fas fa-sign-out-alt"></i></span></Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Uitloggen</ModalHeader>
                    <ModalBody>
                        Weet u zeker dat u wilt uitloggen?
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-secondary"  onClick={AuthService.logOut}>Uitloggen</Button>
                        <Button className="btn btn-cancel"  onClick={this.toggle}>Annuleren</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}