import React, { Component } from 'react';
import { NavOfferRequest } from './NavOfferRequest';
import { OfferOverview } from './OfferOverview';
import { RequestOverview } from './RequestOverview';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

export class Home extends Component {
    static displayName = Home.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            selectedCategories: []
        }
    }

    async componentDidMount() {
        this._isMounted = true;
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    tabChange = (index) => {
        if (this._isMounted && index !== this.state.activeTab) {
            this.setState((prevState) => ({
                prevState,
                activeTab: index,
                selectedCategories: []
            }));
        }
    }

    setSuccesfulToast = (message) => {
        ToastsStore.success(message)
    }

    updateCategories = (cats) => {
        if (this._isMounted) {
            this.setState(prevState => ({
                prevState,
                selectedCategories: cats
            }));
        }
    }

    render() {
        const search = false;
        let tab = null;

        if (this.state.activeTab === 0) {
            tab = <OfferOverview search={search} setSuccesfulToast={this.setSuccesfulToast} selectedCategories={this.state.selectedCategories} updateCategories={this.updateCategories} />;
        } else if (this.state.activeTab === 1) {
            tab = <RequestOverview search={search} setSuccesfulToast={this.setSuccesfulToast} selectedCategories={this.state.selectedCategories} updateCategories={this.updateCategories} />;
        }

        return (
            <div id='page-home'>
                <NavOfferRequest tabChange={this.tabChange} />
                {tab}
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} lightBackground />
            </div>
        );
    }
}
