import React, { Component } from "react";
import ReadMoreAndLess from "react-read-more-less";
import AuthService from "./auth/authService";
import { CategoryCheckBox } from "./CategoryCheckBox";
import { WishListAddRemoveButton } from "./WishListAddRemoveButton";
import HttpClient, { Methods } from "../HttpClient";

export class OfferOverview extends Component {
  static displayName = OfferOverview.name;
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      offers: [],
      categories: [],
      wishListItems: [],
      loading: true,
    };

    this.renderOfferTable = this.renderOfferTable.bind(this);
    this.renderCategories = this.renderCategories.bind(this);
    this.addToWishList = this.addToWishList.bind(this);
    this.deleteFromWishList = this.deleteFromWishList.bind(this);
    this.triggerSearchUpdate = this.triggerSearchUpdate.bind(this);
    this.update = this.update.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;

    let data = [];

    if (navigator.onLine) {
      await fetch("api/Offers")
        .then(async function (result) {
          data = await result.json();
          window.localStorage.setItem("offers", JSON.stringify(data));
        })
        .catch((e) => {});
    } else {
      let parsed = JSON.parse(window.localStorage.getItem("offers"));
      data = parsed !== "" && parsed != null ? parsed : [];
    }

    if (data != null) {
      // Set categories
      var categories = [];
      if (!this.props.search) {
        data.forEach((offer) => {
          if (!categories.includes(offer.category) && offer.category !== "") {
            categories[categories.length] = offer.category;
          }
        });
      }
    }

    // Set wishlist
    let items = "";

    if (navigator.onLine) {
      items = await this.fetchWishListItems();
      if (items === "relogged") {
        items = await this.fetchWishListItems();
      }
    } else {
      let parsed = window.localStorage.getItem("wishlistIds");
      items = parsed != null ? parsed : "";
    }

    if (this._isMounted) {
      // Set data
      this.setState((prevState) => ({
        prevState,
        offers: data,
        wishListItems: items,
        categories: categories,
        loading: false,
      }));
    }
  }

  async fetchWishListItems() {
    var data = "";

    if (AuthService.isLoggedIn()) {
      var result = await HttpClient.doRequest(
        Methods.GET,
        "api/WishList/Items"
      );

      if (result.redirected) {
        AuthService.accessDenied();
      } else {
        data = await result.text();
        window.localStorage.setItem("wishlistIds", data);
      }
    }

    return data;
  }

  async componentWillUnmount() {
    this._isMounted = false;
  }

  update = (checkbox) => {
    var selectedCategories = this.props.selectedCategories;

    if (checkbox.checked) {
      selectedCategories[selectedCategories.length] = checkbox.label;
    } else {
      for (var i = 0; i < selectedCategories.length; i++) {
        if (selectedCategories[i] === checkbox.label) {
          selectedCategories.splice(i, 1);
        }
      }
    }

    this.props.updateCategories(selectedCategories);
  };

  async fetchWishListAdd(itemId) {
    var response = null;

    if (AuthService.isLoggedIn()) {
      var result = await HttpClient.doRequest(
        Methods.POST,
        "api/WishList",
        itemId
      );

      if (result.redirected) {
        AuthService.accessDenied();
      } else if (result.status === 200) {
        response = "added";
      }
    } else {
      window.location.replace("/Identity/Account/Login");
    }
    return response;
  }

  async addToWishList(itemId) {
    var response = await this.fetchWishListAdd(itemId);

    if (this._isMounted && response) {
      this.props.setSuccesfulToast("Toegevoegd!");

      var items = await this.fetchWishListItems();
      this.setState((prevState) => ({
        prevState,
        wishListItems: items,
      }));
    }
  }

  async deleteFromWishList(itemId) {
    var response = await HttpClient.doRequest(
      Methods.DELETE,
      "api/WishList",
      itemId
    );
    if (response.status === 200 && this._isMounted) {
      this.props.setSuccesfulToast("Verwijderd!");

      var items = await this.fetchWishListItems();
      this.setState((prevState) => ({
        prevState,
        wishListItems: items,
      }));
    }
  }

  renderOfferTable() {
    var offers = [];
    if (this.props.search) {
      if (this.props.searchQuery.length > 0) {
        this.state.offers.forEach((offer) => {
          if (
            offer.category
              .toLowerCase()
              .includes(this.props.searchQuery.toLowerCase()) ||
            offer.companyName
              .toLowerCase()
              .includes(this.props.searchQuery.toLowerCase()) ||
            offer.offerDescription
              .toLowerCase()
              .includes(this.props.searchQuery.toLowerCase())
          ) {
            offers[offers.length] = offer;
          }
        });
      } else {
        offers = this.state.offers;
      }
    } else {
      if (this.props.selectedCategories.length > 0) {
        this.state.offers.forEach((offer) => {
          if (this.props.selectedCategories.includes(offer.category)) {
            offers[offers.length] = offer;
          }
        });
      } else {
        offers = this.state.offers;
      }
    }

    return (
      <div id="overview">
        {offers.map((offer) => (
          <div key={offer.offerId} className="row">
            <table>
              <tbody>
                <tr>
                  <td className="td-name">Categorie:</td>
                  <td className="td-description">{offer.category}</td>
                </tr>
                <tr>
                  <td className="td-name">Bedrijf:</td>
                  <td className="td-description">{offer.companyName}</td>
                </tr>
              </tbody>
            </table>

            <h3>Omschrijving:</h3>
            <ReadMoreAndLess
              charLimit={150}
              readMoreText="Lees meer"
              readLessText="Lees minder"
            >
              {offer.offerDescription}
            </ReadMoreAndLess>

            <div className="align-right">
              <WishListAddRemoveButton
                itemId={offer.offerId}
                inWishlist={this.state.wishListItems.match(offer.offerId)}
                addToWishList={this.addToWishList}
                deleteFromWishList={this.deleteFromWishList}
              />
            </div>
            <hr />
          </div>
        ))}
      </div>
    );
  }

  renderCategories() {
    return (
      <div id="accordion">
        <div className="acc-categories" id="headingOne">
          <button
            className="btn btn-link collapsed"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            CATEGORIE&#203;N
          </button>
          <button
            id="arrow"
            className="btn btn-link collapsed"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <img
              src="/media/images/category-toggle-icon.png"
              id="category-toggle-icon"
              alt="Toggle category"
            />
          </button>
        </div>
        <div
          id="collapseOne"
          className="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          {/*<div className="card-body">
                        <div className="category-item">
                            <div className="checkbox">
                                <input type="checkbox" id="checkbox_all-categories" />
                                <label className="checkmark" htmlFor="checkbox_all-categories"></label>
                            </div>
                        </div>
                        <div className="category-item">
                            Alle categorie&euml;n
                        </div>
                    </div>*/}

          {this.state.categories.map((cat) => (
            <div key={cat} className="card-body">
              <div className="category-item">
                <CategoryCheckBox update={this.update}>{cat}</CategoryCheckBox>
              </div>
              <div className="category-item">{cat}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  triggerSearchUpdate(input) {
    this.searchUpdate(input.target.value);
  }

  searchUpdate(value) {
    this.props.updateSearchQuery(value);
  }

  clearSearch() {
    this.props.updateSearchQuery("");
  }

  renderSearch() {
    return (
      <div className="search">
        <div className="form-group has-search">
          <span className="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            className="form-control"
            placeholder="Zoeken op trefwoord.."
            value={this.props.searchQuery}
            onChange={this.triggerSearchUpdate}
          />
          <button
            className="btn btn-secondary searchclear"
            onClick={this.clearSearch}
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <div className="text-center">
        <div className="spinner-border text-danger" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) : (
      this.renderOfferTable()
    );

    let searchWidget = !this.props.search
      ? this.state.loading
        ? null
        : this.renderCategories()
      : this.state.loading
      ? null
      : this.renderSearch();

    return (
      <div id="OfferOverview">
        {searchWidget}
        {contents}
      </div>
    );
  }
}
