import React, { Component } from 'react';
import ReadMoreAndLess from 'react-read-more-less';
import { CategoryCheckBox } from './CategoryCheckBox';
import { WishListAddRemoveButton } from './WishListAddRemoveButton';
import AuthService from './auth/authService';
import HttpClient, { Methods } from '../HttpClient';

export class RequestOverview extends Component {
    static displayName = RequestOverview.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            requests: [],
            categories: [],
            wishListItems: [],
            loading: true
        };

        this.renderRequestTable = this.renderRequestTable.bind(this);
        this.renderCategories = this.renderCategories.bind(this);
        this.addToWishList = this.addToWishList.bind(this);
        this.deleteFromWishList = this.deleteFromWishList.bind(this);
        this.triggerSearchUpdate = this.triggerSearchUpdate.bind(this);
        this.update = this.update.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    async componentDidMount() {
        this._isMounted = true;

        let data = [];

        if (navigator.onLine) {
            await HttpClient.doRequest(Methods.GET, 'api/Requests').then(async function (result) {
                data = await result.json();
                window.localStorage.setItem('requests', JSON.stringify(data));
            }).catch((e) => { });
        } else {
            var parsed = JSON.parse(window.localStorage.getItem('requests'));
            data = parsed != "" && parsed != null ? parsed : [];
        }

        if (data != null) {
            // Set categories
            var categories = [];
            if (!this.props.search) {
                data.map((currentOffer, index, requests) => {
                    requests.forEach((request) => {
                        if (!categories.includes(request.category) && request.category !== '') {
                            categories[categories.length] = request.category;
                        }
                    });
                });
            }
        }

        // Set wishlist
        let items = "";

        if (navigator.onLine) {
            items = await this.fetchWishListItems();
            if (items == 'relogged') {
                items = await this.fetchWishListItems();
            }
        } else {
            var parsed = window.localStorage.getItem('wishlistIds');
            items = parsed != null ? parsed : "";
        }

        if (this._isMounted) {
            // Set data
            this.setState((prevState) => ({
                prevState,
                requests: data,
                wishListItems: items,
                categories: categories,
                loading: false
            }));
        }
    }

    async fetchWishListItems() {
        var data = "";

        if (AuthService.isLoggedIn()) {
            var result = await HttpClient.doRequest(Methods.GET, 'api/WishList/Items');

            if (result.redirected) {
                AuthService.accessDenied();
            } else {
                data = await result.text();
                window.localStorage.setItem('wishlistIds', data);
            }
        }

        return data;
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    update = (checkbox) => {
        var selectedCategories = this.props.selectedCategories;

        if (checkbox.checked) {
            selectedCategories[selectedCategories.length] = checkbox.label;
        } else {
            for (var i = 0; i < selectedCategories.length; i++) {
                if (selectedCategories[i] == checkbox.label) {
                    selectedCategories.splice(i, 1);
                }
            }
        }

        this.props.updateCategories(selectedCategories);
    }

    async fetchWishListAdd(itemId) {
        var response = null;

        if (AuthService.isLoggedIn()) {
            var result = await HttpClient.doRequest(Methods.POST, 'api/WishList', itemId);
            if (result.redirected) {
                AuthService.accessDenied();
            }
            else if (result.status === 200) {
                response = 'added';
            }
        } else {
            window.location.replace("/Identity/Account/Login");
        }
        return response;
    }

    async addToWishList(itemId) {
        var response = await this.fetchWishListAdd(itemId);

        if (this._isMounted && response) {
            this.props.setSuccesfulToast("Toegevoegd!");

            var items = await this.fetchWishListItems();
            this.setState((prevState) => ({
                prevState,
                wishListItems: items
            }));
        }
    }

    async deleteFromWishList(itemId) {
        var response = await HttpClient.doRequest(Methods.DELETE, 'api/WishList', itemId);
        if (response.status === 200 && this._isMounted) {
            this.props.setSuccesfulToast("Verwijderd!");

            var items = await this.fetchWishListItems();
            this.setState((prevState) => ({
                prevState,
                wishListItems: items
            }));
        }
    }

    renderRequestTable() {
        var requests = [];
        if (this.props.search) {
            if (this.props.searchQuery.length > 0) {
                this.state.requests.map((request) => {
                    if (request.category.toLowerCase().includes(this.props.searchQuery.toLowerCase())
                        || request.companyName.toLowerCase().includes(this.props.searchQuery.toLowerCase())
                        || request.requestDescription.toLowerCase().includes(this.props.searchQuery.toLowerCase())) {
                        requests[requests.length] = request;
                    }
                });
            } else {
                requests = this.state.requests;
            }
        } else {
            if (this.props.selectedCategories.length > 0) {
                this.state.requests.map((request) => {
                    if (this.props.selectedCategories.includes(request.category)) {
                        requests[requests.length] = request;
                    }
                });
            } else {
                requests = this.state.requests;
            }
        }

        return (
            <div id='overview'>
                {requests.map(request =>
                    <div key={request.requestId} className="row">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="td-name">Categorie:</td>
                                    <td className="td-description">{request.category}</td>
                                </tr>
                                <tr>
                                    <td className="td-name">Maatschappelijke organisatie:</td>
                                    <td className="td-description">{request.companyName}</td>
                                </tr>
                            </tbody>
                        </table>

                        <h3>Omschrijving:</h3>
                        <ReadMoreAndLess charLimit={150} readMoreText="Lees meer" readLessText="Lees minder">
                            {request.requestDescription}
                        </ReadMoreAndLess>

                        <div className="align-right">
                            <WishListAddRemoveButton itemId={request.requestId} inWishlist={this.state.wishListItems.match(request.requestId)} addToWishList={this.addToWishList} deleteFromWishList={this.deleteFromWishList} />
                        </div>
                        <hr />
                    </div>
                )
                }
            </div >
        );
    }

    renderCategories() {
        return (
            <div id="accordion">
                <div className="acc-categories" id="headingOne">
                    <button className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne">CATEGORIE&#203;N</button>
                    <button id="arrow" className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"><img src="/media/images/category-toggle-icon.png" id="category-toggle-icon" /></button>
                </div>
                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    {/*<div className="card-body">
                        <div className="category-item">
                            <div className="checkbox">
                                <input type="checkbox" id="checkbox_all-categories" />
                                <label className="checkmark" htmlFor="checkbox_all-categories"></label>
                            </div>
                        </div>
                        <div className="category-item">
                            Alle categorie&euml;n
                        </div>
                    </div>*/}

                    {this.state.categories.map((cat) =>
                        <div key={cat} className="card-body">
                            <div className="category-item">
                                <CategoryCheckBox update={this.update}>{cat}</CategoryCheckBox>
                            </div>
                            <div className="category-item">
                                {cat}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    triggerSearchUpdate(input) {
        this.searchUpdate(input.target.value);
    }

    searchUpdate(value) {
        this.props.updateSearchQuery(value);
    }

    clearSearch() {
        this.props.updateSearchQuery("");
    }

    renderSearch() {
        return (
            <div className="search">
                <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input type="text" className="form-control" placeholder="Zoeken op trefwoord.." value={this.props.searchQuery} onChange={this.triggerSearchUpdate} />
                    <button className="btn btn-secondary searchclear" onClick={this.clearSearch}><i className="fas fa-times"></i></button>
                </div>
            </div>
        );
    }

    render() {
        let contents = this.state.loading ?
            <div className="text-center">
                <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            : this.renderRequestTable();

        let searchWidget = !this.props.search ? (this.state.loading ? null : this.renderCategories()) : (this.state.loading ? null : this.renderSearch());

        return (
            <div id="RequestOverview">
                {searchWidget}
                {contents}
            </div>
        );
    }
}
