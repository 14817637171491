import React, { Component } from 'react';
import { NavButtonOfferRequest } from './NavButtonOfferRequest';

export class NavOfferRequest extends Component {
    static displayName = NavOfferRequest.name;

    constructor(props) {
        super(props);
        this.state = {
            activeButton: 0,
        };
    }

    clicked = (index) => {
        this.setState({
            activeButton: index
        });

        this.props.tabChange(index);
    }

    render() {
        const buttons = [
            { name: "Aanbod" },
            { name: "Vraag" }
        ]

        return (
            <nav id="NavOfferRequest">
                {buttons.map((button, i) =>
                    <NavButtonOfferRequest key={button.name} isLoading={this.props.isLoading} index={i} isActive={this.state.activeButton === i} onClick={this.clicked}>{button.name}</NavButtonOfferRequest>
                )
                }
            </nav>
        )
    }
}